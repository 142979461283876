import { Component, OnInit } from '@angular/core';
import { ResumeCompanyService } from '../../../admin/company/resume.service';
import { LoginService } from '../../../login/login.service';
import { ReportsService } from '../../../report-tree/reports/reports.service';
@Component({
    selector: 'app-total-time-usage',
    templateUrl: './total-time.component.html',
    styleUrls: ['./total-time.component.scss']
})
export class TotalTimeUsageComponent implements OnInit {
    constructor(
        public loginService: LoginService, 
        public reportService: ReportsService,
        private ResumeCompService: ResumeCompanyService,
    ) { }
    company: any;
    totalTime: any;
    ngOnInit() {
        this.ResumeCompService.getCompanyResume(this.loginService.user.team.company._id)
        .subscribe(async (resp: any) => {
            this.company = await resp.json();
            console.log(this.company)

            this.totalTime = this.loginService.calcDuration(this.company.plan.usedTime);
        });

        
        // this.reportService.getTotalTimeByTeam().subscribe(async (result) => {
        //     let resp = await result.json();
        //     this.totalTime = resp;
        // });
    }
}
