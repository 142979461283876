import {  ChangeDetectorRef, NgZone, Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators, ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { SuperAdminService } from '../superadmin.service';
import { SelectListDialogComponent } from '../../admin/pending-devices/edit-device-dialog/select-list-dialog/select-list.component';
import { ToastrService } from 'ngx-toastr';
import { UploadComponent } from '../../util/upload/upload.component';

@Component({
    selector: 'custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})
export class CustomDialogComponent implements OnInit, OnDestroy, AfterViewChecked {
    
    @ViewChild('upload') uploadComp: UploadComponent;
    @ViewChild('thumbDevice') thumbDev: ElementRef;
    
    packageForm: FormGroup;
    accountForm: FormGroup;
    deviceForm: FormGroup;
    modelForm: FormGroup;
    servicePackage = {name: '', deviceFarms: []};
    device = {alias: '', deviceHub: {name: ''}, model: {name:''}, OS:{platform:'',version:0},serial:''};
    platforms = [{name: 'Android'},{name: 'iOS'}];
    uploadSub: any;
    isLoading: Boolean = false;
    respError = {type: '', message: ''}
    
    constructor(
        private router: Router,
        private changeDetector: ChangeDetectorRef,
        private zone: NgZone,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private superAdminService: SuperAdminService,
        public dialogRef: MatDialogRef<CustomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }
    
    static MatchPassword(control: AbstractControl) {
        let password = control.get('password').value;
        let confirmPassword = control.get('confirmPassword').value;
        if(password != confirmPassword) {
            control.get('confirmPassword').setErrors( {ConfirmPassword: true} );
        } else {
            return null
        }
    }
    
    ngAfterViewChecked(): void {
        if(this.data.type == 'upload' && (this.data.deviceModel.urlThumb || this.data.deviceModel.thumb)){
            let thumb = this.thumbDev.nativeElement as HTMLElement;
            
            thumb.style.display = `inline-flex`;
            thumb.style.backgroundImage = `url(${this.getThumbUrl(this.data.deviceModel)})`;
        }
        
        if(this.data.type == 'upload'){
            this.uploadSub = this.uploadComp.uploadEvent.subscribe((val: any) => {
                let thumb = this.thumbDev.nativeElement as HTMLElement;
                
                thumb.style.display = `inline-flex`;
                thumb.style.backgroundImage = `url("${val.url}")`;
                this.data.deviceModel.urlThumb = val.url;
                
                this.superAdminService.saveDeviceModel(this.data.deviceModel).subscribe(async resp => {
                    let data = await resp.json();
                    
                    if(data.message){
                        this.toastr.success(data.message);
                        this.dialogRef.close();
                    }
                })
            });
        }
    }
    
    ngOnInit() {
        if(this.data.type == 'model'){
            if(!this.data.model.logicalResolution){
                this.data.model.logicalResolution = {width: 0, height: 0}
            }
            
            this.modelForm = new FormGroup({
                name: new FormControl({value: this.data.model.name}, Validators.required),
                manufacturer: new FormControl({value: this.data.model.manufacturer.name}, Validators.required),
                width: new FormControl({value: this.data.model.resolution.width}, Validators.required),
                height: new FormControl({value: this.data.model.resolution.height}, Validators.required),
                Lwidth: new FormControl({value: this.data.model.logicalResolution.width}),
                Lheight: new FormControl({value: this.data.model.logicalResolution.height}),
            });
        }
        
        if(this.data.type == 'package'){
            this.packageForm = new FormGroup({
                name: new FormControl({value: this.servicePackage.name}, Validators.required),
            });
        }
        
        if(this.data.type == 'device'){
            this.device = this.data.device;
            
            this.deviceForm = new FormGroup({
                alias: new FormControl({value: this.device.alias}, Validators.required),
                serial: new FormControl({value: this.device.serial}, Validators.required),
                platform: new FormControl({value: this.device.OS.platform}, Validators.required),
                version: new FormControl({value: this.device.OS.version}, [Validators.required, Validators.pattern('[0-9.]*')]),
                deviceHub: new FormControl({value: this.device.deviceHub.name}, Validators.required),
                model: new FormControl({value: this.device.model.name}, Validators.required),
            })
        }
        
        if(this.data.type == 'account'){
            if(this.data.acc.roles && this.data.acc.roles.indexOf('ADMIN') > -1){
                this.data.acc.isAdmin = true
            }
            if(!this.data.acc.teams){ this.data.acc.teams = [] }
            if(this.data.acc._id){
                this.data.acc.teams = this.data.teams.filter(team => {
                    return team.allowedUsers.some(allowedUser => {
                        return allowedUser === this.data.acc._id
                    })
                });
            }

            this.accountForm = new FormGroup({
                firstName: new FormControl({value: this.data.acc.firstName}, Validators.required),
                lastName: new FormControl({value: this.data.acc.lastName}, Validators.required),
                team: new FormControl({value: this.data.acc.team.name}, Validators.required), 
                teams: new FormControl({value: this.data.acc.teams}, Validators.required), 
                username: new FormControl({value: this.data.acc.username}, Validators.required),
                password: new FormControl({value: this.data.acc.password}),
                confirmPassword: new FormControl({value: this.data.acc.confirmPassword}),
                email: new FormControl({value: this.data.acc.email}, [Validators.required, Validators.email]),
                isAdmin: new FormControl({value: this.data.acc.isAdmin})
            },{
                validators: CustomDialogComponent.MatchPassword
            });
        }
    }
    
    getThumbUrl(model) {
        return model.urlThumb ? '"'+model.urlThumb+'"' : 'assets/images/thumbs/'+model.thumb;
    }
    
    savePackage(){
        this.isLoading = true;
        
        this.superAdminService.savePackage(this.servicePackage).subscribe(async resp => {
            let data = await resp.json();
            this.data.servicePackages.push(data);
            this.isLoading = false;
            this.dialogRef.close();
        });
    }
    
    saveDevice(){
        this.isLoading = true;
        this.superAdminService.saveDevice(this.device).subscribe(async resp => {
            let data = await resp.json();
            this.toastr.success('Device Salvo!');
            
            if(this.data.isNew){
                this.data.deviceList.push(data)
            }
            
            this.isLoading = false;
            this.dialogRef.close();
        });
    }
    
    saveModel(){
        this.isLoading = true;
        this.superAdminService.saveDeviceModel(this.data.model).subscribe(async resp => {
            this.toastr.success('Modelo Salvo!');
            if(this.data.isNew){
                let model = await resp.json()
                this.data.modelList.push(model)
            }
            this.isLoading = false;
            this.dialogRef.close();
        });
    }
    
    saveAccount(){
        if(this.accountForm.valid){
            // let teamId = this.data.acc.team._id;
            // this.data.acc.team = teamId;
            if(this.data.acc.teams.length){
                this.data.acc.teams = this.data.acc.teams.map(t => t._id);
            } else {
                this.toastr.warning(`Selecione ao menos um time!`);
                return ;
            }
            this.isLoading = true;
            this.superAdminService.saveAccount(this.data.acc).subscribe(async resp => {
                this.toastr.success('Dados Atualizados!', '', {closeButton: true});
                let nAcc = await resp.json();
                if(this.data.isNew){
                    this.data.accountList.push(nAcc);
                }
                
                this.isLoading = false;
                this.dialogRef.close()
            }, err => {
                let resp = err.json();
                this.isLoading = false;
                if(resp.type == 'username'){
                    this.accountForm.get('username').setErrors({})
                    this.respError.type = 'username';
                }
                if(resp.type == 'password'){
                    this.accountForm.get('password').setErrors({});
                    this.respError.type = 'password';
                }
                this.respError.message = resp.message;
            });
        }
    }
    
    showFarm(farm, list){
        return list.map(d => d._id).includes(farm._id) ? false : true;
    }
    
    addFarm(farm, list){
        list.push(farm);
    }
    
    removeFarm(idx, list){
        list.splice(idx,1);
    }
    
    createFarm(){
        const farm = prompt('Digite o nome da nova farm');
        
        if (!farm) {
            return;
        }
        
        this.superAdminService.createFarm(farm).subscribe(async resp => {
            let data = await resp.json();
            this.data.deviceFarms.push(data);
        });
    }
    
    uploadParams() {
        return {type: 'img', name: this.data.deviceModel.name};
    }
    
    list(type, list){
        let data: any = {list, type};
        
        switch (type) {
            
            case 'platform':
            case 'deviceHub':
            case 'deviceModel':
                data.device = this.data.device;
            break;
            
            case 'teams':
                data.account = this.data.acc
            break;
            
            default:
            break;
        }
        
        this.dialog.open(SelectListDialogComponent, {
            width: '400px',
            height: '400px',
            data
        })
    }
    
    listM(type, list){
        this.dialog.open(SelectListDialogComponent, {
            width: '400px',
            height: '400px',
            data: {
                list,
                type,
                model: this.data.model
            }
        })
    }
    
    removeTeam(idx){
        this.data.acc.teams.splice(idx, 1);
    }

    ngOnDestroy(): void {
        this.changeDetector.detach();
    }
}
