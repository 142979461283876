import { OnInit, OnDestroy, Component, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { ResumeCompanyService } from './resume.service';
import { Router } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'resume-company',
    templateUrl: './resume.component.html',
    styleUrls: ['./resume.component.scss']
})


export class ResumeCompanyComponent implements OnInit, OnDestroy, AfterViewChecked {

    company: any;
    planSub: any;

    constructor(
		private router: Router,
        private loginService: LoginService,
		private dialog: MatDialog,
        private changeDetector: ChangeDetectorRef,
        private ResumeCompService: ResumeCompanyService,
	) {
	}

    ngOnInit(): void {
        if (this.isAdmin()) {
            this.planSub = this.ResumeCompService.getCompanyResume(this.loginService.user.team.company._id)
            .subscribe(async (resp: any) => {
                this.company = await resp.json();
                console.log(this.company)
            });
        } else {
            this.router.navigate(['/']);
        }
    }

    calcValuePrice(timeInSeconds){
        var price = (timeInSeconds / 60)*this.company.plan.valuePerMinute;

        return Math.round(price * 100)/100;
    }

    isAdmin(){
        return this.loginService.user.roles && (this.loginService.user.roles.indexOf('ADMIN') > -1 || this.loginService.user.roles.indexOf('ROOT') > -1);
    }


    getDate(date){
        return date ? new Date(date).toLocaleString('BR') : '';
    }
    
    calcPercent(total, used){
        return Math.round((used/total)*100);
    }
    
    refresh(){

    }

    ngOnDestroy(): void {
        
    }

    ngAfterViewChecked(): void {
        this.changeDetector.detectChanges();
    }
}
