import {  ChangeDetectorRef, Input, NgZone, Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs'; //possivel troca pra 'rxjs'
import { DevicesService, DeviceFilter } from '../../devices/devices.service';
import { ConnectService } from '../connect.service';
import { MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LoginService } from '../../login/login.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../../app.service';
import { TestsService } from '../../tests/tests.service';


@Component({
	selector: 'app-fullscreen-dialog',
	templateUrl: './fullscreen-dialog.component.html',
	styleUrls: ['./fullscreen-dialog.component.scss']
})
export class FullScreenDialogComponent implements OnInit {
	
	@Input() deviceObject: any;
	
	@ViewChild('canvasFull') canvasFull: ElementRef;
	
	screenSubscr: any;
	screenWidth: any;
	stageResizeSubscription: any;
	showInspector: boolean = false;
	isPressed: boolean;
	clickObj: { origin: { x: number; y: number; }; origin2: { x: number; y: number; }; dest: { x: number; y: number; }; dest2: { x: number; y: number; }; path: any[]; duration: number; reverse: boolean; };
	mouseOrigin: { x: any; y: any; };
	touch1: any;
	touch2: any;
	inspector: any;
	useLandscape: boolean;
	
	constructor(
		private testsService: TestsService,
		private toastr: ToastrService,
		private changeDetector: ChangeDetectorRef,
		private zone: NgZone,
		private deviceService: DevicesService,
		private appService: AppService,
		private connectService: ConnectService,
		// public dialogRef: MatDialogRef<FullScreenDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
		) {
		}
		
		ngOnInit() {
			console.log("this.deviceObject");
			this.useLandscape = false;
			this.deviceObject = this.connectService.fullScreenDevice;
			
			this.connectService.screenReceiveEvent.subscribe((deviceObject: any) => {
				if(!this.connectService.fullScreenDevice){
					return;
				} else {
					this.deviceObject = this.connectService.fullScreenDevice;
				}
				
				if (deviceObject !== this.connectService.fullScreenDevice) { return; }
				
				if(!this.canvasFull){ return; }
				
				const g = this.canvasFull.nativeElement.getContext('2d');
				
				let blob = new Blob([deviceObject.screen], { type: 'image/jpeg' });
				const URL = window.URL || window['webkitURL'];
				let img = new Image();
				let u = URL.createObjectURL(blob);
				const ref = this;
				
				img.onload = function () {
					if(img.width > img.height){
						ref.useLandscape = true;
					}
					
					ref.canvasFull.nativeElement.setAttribute('width', img.width);
					ref.canvasFull.nativeElement.setAttribute('height', img.height);
					
					g.drawImage(img, 0, 0);
					img.onload = null;
					img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
					img = null;
					u = null;
					blob = null;
				};
				
				img.src = u;
			});
			
			this.callScreen();
		}
		
		actionPressKey(key: any, replicated: Boolean) {
			
			const actionRequestId = this.appService.uuid();
			const ref = this;
			
			this.connectService.sendAction(
				this.deviceObject.device._id,
				actionRequestId, 'live', {
					action: 'presskey',
					reference: key,
				}).then((result: any) => {
					// console.log(result.data);
					if (result.data.status === 'ERROR') {
						const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
						if (!retry) {
							ref.connectService.releaseConnection(ref.deviceObject.device);
						}
					}
				});
			}
			
			callScreen() {
				if(this.deviceObject){
					this.connectService.requestScreen(this.deviceObject.device._id);
				}
			}
			
			renderScreen(deviceObject: any) {
				this.canvasFull.nativeElement.classList.remove('empty');
				
				const g = this.canvasFull.nativeElement.getContext('2d');
				let blob = new Blob([deviceObject.screen], { type: 'image/jpeg' });
				const URL = window.URL || window['webkitURL'];
				let img = new Image();
				let u = URL.createObjectURL(blob);
				const ref = this;
				
				let hRatio;
				
				if (deviceObject.screenResolution) {
					hRatio = (window.innerHeight - 100) / deviceObject.screenResolution.height;
				}
				
				img.onload = function () {
					if(img.width > img.height){
						ref.useLandscape = true;
					}
					// O Player iOS tem duas formas de enviar a img, se for com minicap ela vem quadrada com barras pretas na lateral
					if(img.width == img.height && ref.deviceObject.device.OS.platform.toLowerCase() == 'ios' && ref.deviceObject.iOSorientation == 'portrait'){
						let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
						let canvas = ref.canvasFull.nativeElement;
						canvas.setAttribute('width', Math.round( img.height / rt ));
						canvas.setAttribute('height', img.height);
						ref.screenWidth = (canvas.offsetWidth);
						ref.deviceObject.width = (canvas.offsetWidth);
						
						//agr agt calcula pra n printar a barra preta
						let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
						g.drawImage(img, calcW, 0);
					} else if(img.width == img.height && ref.deviceObject.device.OS.platform.toLowerCase() == 'ios' && ref.deviceObject.iOSorientation == 'landscape') {
						let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
						let canvas = ref.canvasFull.nativeElement;
						canvas.setAttribute('width', img.height);
						canvas.setAttribute('height', Math.round( img.height / rt ));
						ref.screenWidth = (canvas.offsetWidth);
						ref.deviceObject.width = (canvas.offsetWidth);
						
						let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
						g.drawImage(img, 0, calcW);
					} else {
						ref.canvasFull.nativeElement.setAttribute('width', img.width);
						ref.canvasFull.nativeElement.setAttribute('height', img.height);
						ref.screenWidth = (ref.canvasFull.nativeElement.offsetWidth);
						ref.deviceObject.width = (ref.canvasFull.nativeElement.offsetWidth);
						
						g.drawImage(img, 0, 0);
					}
					
					img.onload = null;
					img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
					img = null;
					u = null;
					blob = null;
					
					let now = new Date().getTime();
					ref.deviceObject.lastScreenTime = now;
				};
				img.src = u;
			}
			
			getMouseOffset(event: any) {
				let canva = this.canvasFull.nativeElement;
				const deviceModel = this.deviceObject.device.model;
				const isLandscape = canva.offsetWidth > canva.offsetHeight;
				const offset = { x: 0, y: 0, };
				
				const clickInPercent = {
					x: (event.offsetX / canva.offsetWidth),
					y: (event.offsetY / canva.offsetHeight)
				}
				
				if(this.deviceObject.device.OS.platform.toLowerCase() === 'ios'){
					if(isLandscape){
						offset.x = (deviceModel.logicalResolution.height * clickInPercent.x);
						offset.y = (deviceModel.logicalResolution.width * clickInPercent.y);
					} else {
						offset.x = (deviceModel.logicalResolution.width * clickInPercent.x);
						offset.y = (deviceModel.logicalResolution.height * clickInPercent.y);
					}
				}else{
					if(isLandscape){
						offset.x = (deviceModel.resolution.height * clickInPercent.x);
						offset.y = (deviceModel.resolution.width * clickInPercent.y);
					} else {
						offset.x = (deviceModel.resolution.width * clickInPercent.x);
						offset.y = (deviceModel.resolution.height * clickInPercent.y);
					}
				}
				console.log(`Click na pos [X]:${offset.x} [Y]:${offset.y}`);
				
				return offset;
			}
			
			actionPinch(val: any) {
				const actionRequestId = this.appService.uuid();
				const ref = this;
				
				this.connectService.sendAction(
					this.deviceObject.device._id,
					actionRequestId, 'live', {
						action: 'pinch',
						method: 'relative',
						reference: val,
					}).then((result: any) => {
						if (result.data.status === 'ERROR') {
							const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
							if (!retry) {
								ref.connectService.releaseConnection(ref.deviceObject.device);
							}
						}
					});
				}
				
				getReverseOffset(event: any) {
					const angle = Math.atan2(event.offsetY - this.mouseOrigin.y, event.offsetX - this.mouseOrigin.x) * 180 / Math.PI;
					const reverseAngle = angle + 180;
					
					const distx = this.mouseOrigin.x - event.offsetX;
					const disty = this.mouseOrigin.y - event.offsetY;
					
					const dist = Math.sqrt(distx * distx + disty * disty);
					
					return {
						offsetY: this.mouseOrigin.y + (Math.sin(reverseAngle * Math.PI / 180) * dist),
						offsetX: this.mouseOrigin.x + (Math.cos(reverseAngle * Math.PI / 180) * dist)
					};
				}
				
				onCanvasMouseDown(event: any, replicated: Boolean = false) {
					
					event.preventDefault();
					
					this.isPressed = true;
					
					this.clickObj = {
						origin: { x: 0, y: 0 },
						origin2: { x: 0, y: 0 },
						dest: { x: 0, y: 0 },
						dest2: { x: 0, y: 0 },
						path: [],
						duration: 0,
						reverse: false,
					};
					
					this.clickObj.origin = this.getMouseOffset(event);
					this.clickObj.duration = new Date().getTime();
					
					this.mouseOrigin = {
						x: event.offsetX,
						y: event.offsetY
					};
					
					this.touch1.nativeElement.style.display = 'block';
					this.touch1.nativeElement.style.top = `${this.mouseOrigin.y - 15}px`;
					this.touch1.nativeElement.style.left = `${this.mouseOrigin.x}px`;
					
					if (event.altKey /* Valida de o AltKey esta pressionado */) {
						this.touch2.nativeElement.style.display = 'block';
						this.touch2.nativeElement.style.top = this.touch1.nativeElement.style.top;
						this.touch2.nativeElement.style.left = this.touch1.nativeElement.style.left;
						
						this.clickObj.origin2 = this.getMouseOffset(event);
						
						this.clickObj.path.push({
							finger1: this.getMouseOffset(event),
							finger2: this.getMouseOffset(event),
							duration: new Date().getTime()
						});
					} else {
						this.touch2.nativeElement.style.display = 'none';
					}
					
					this.clickObj.origin = this.getMouseOffset(event);
					
					if (this.showInspector) {
						this.inspector.bestMatch = undefined;
						this.inspector.selectElementByPosition(this.clickObj.origin);
					}
				}
				
				onCanvasMouseMove(event: any, replicated: Boolean = false) {
					console.log(event.offsetX);
					
					if (!this.isPressed) {
						return;
					}
					
					this.touch1.nativeElement.style.top = `${event.offsetY - 15}px`;
					this.touch1.nativeElement.style.left = `${event.offsetX}px`;
					
					if (event.altKey /* Valida de o AltKey esta pressionado */) {
						
						// if (this.clickObj.path[this.clickObj.path.length - 1].duration + 2 < new Date().getTime() ) {
						const resverseOffset = this.getReverseOffset(event);
						this.touch2.nativeElement.style.top = `${resverseOffset.offsetY}px`;
						this.touch2.nativeElement.style.left = `${resverseOffset.offsetX}px`;
						
						this.clickObj.path.push({
							finger1: this.getMouseOffset(event),
							finger2: this.getMouseOffset(this.getReverseOffset(event)),
							duration: new Date().getTime()
						});
						// }
					}
					
				}
				
				onCanvasMouseUp(event: any, replicated: Boolean = false) {
					// activeEnhancedKeyboard on click!
					this.connectService.activeEnhancedKeyboard = true;
					
					event.preventDefault();
					
					this.isPressed = false;
					
					this.clickObj.dest = this.getMouseOffset(event);
					
					if (event.altKey /* Valida de o AltKey esta pressionado */) {
						this.clickObj.dest2 = this.getMouseOffset(this.getReverseOffset(event));
						
						this.clickObj.path.push({
							finger1: this.getMouseOffset(event),
							finger2: this.getMouseOffset(this.getReverseOffset(event)),
							duration: new Date().getTime()
						});
						
						this.clickObj.path.map((el, index) => {
							if (index === 0) {
								el.duration = 0;
							} else {
								el.duration = el.duration - this.clickObj.duration;
							}
							return el;
						});
						
						if (event.which === 3) {
							this.clickObj.path = this.clickObj.path.reverse();
							this.clickObj.reverse = true;
						} else {
							this.clickObj.reverse = false;
						}
						
						this.actionPinch(this.clickObj);
						return;
					}
					
					this.clickObj.duration = new Date().getTime() - this.clickObj.duration;
					const actionRequestId = this.appService.uuid();
					
					if (this.showInspector) {
						this.inspector.bestMatch = undefined;
						this.inspector.selectElementByPosition(this.clickObj.origin);
					} else if (this.deviceObject.appRunning) {
						
						if (!replicated) {
							this.testsService.testComponent.addTouchAction(this.clickObj, 'relative', this.deviceObject);
						}
						
						this.actionTouchXY(this.clickObj);
					}
				}
				
				actionTouchXY(val: any) {
					const actionRequestId = this.appService.uuid();
					const ref = this;
					
					this.connectService.sendAction(
						this.deviceObject.device._id,
						actionRequestId, 'live', {
							action: 'touch',
							method: 'relative',
							reference: val,
						}).then((result: any) => {
							if (result.data.status === 'ERROR') {
								const retry = confirm(`OPS! Ocorreu um erro ao executar o comando: \r\n ${result.data.error.data} \r\n \r\n Deseja manter a conexão?`);
								if (!retry) {
									ref.connectService.releaseConnection(ref.deviceObject.device);
								}
							}
						});
					}
					
					clearCache(){
						let result = prompt(`Qual o 'Package' do app que deseja limpar o cache?`);
						if(result){
							const actionRequestId = this.appService.uuid();
							this.connectService.sendAction(
								this.deviceObject.device._id,
								actionRequestId, 'live', {
									action: 'clearcache',
									reference: result,
								})
								.then((result: any) => {
									// console.log(result.data);
									if (result.data.status === 'ERROR') {
										this.toastr.error('Erro ao limpar aplicativo.', 'Error!', {closeButton: true});
									}else{
										this.toastr.success('App resetado.', 'Boa!', {closeButton: true});
									}
								});
							}
						}
						
						downloadImg() {
							setTimeout(() => {
								
								let blob = new Blob([this.deviceObject.screen], { type: 'image/jpeg' });
								const URL = window.URL || window['webkitURL'];
								let u = URL.createObjectURL(blob);
								
								var element = document.createElement('a');
								element.setAttribute('href', u);
								element.setAttribute('download', `screenshot_${new Date().getTime()}.jpg`);
								
								element.style.display = 'none';
								document.body.appendChild(element);
								
								element.click();
								
								document.body.removeChild(element);
							}, 800);
						}
						
						recordExploration() {
							if (this.deviceObject.isRecording) {
								this.deviceService.finishLiveReport(this.deviceObject.livereportId)
								.subscribe(async resp => {
									this.deviceObject.livereportId = '';
									this.deviceObject.isRecording = false;
								})
								
							} else {
								let result = prompt(`Qual será o nome do relatório?`);
								let dt = new Date().toLocaleString('pt-br')
								if (result) {
									this.deviceService.createLiveReport(result, this.deviceObject.device._id)
									.subscribe(async resp => {
										let data = await resp.json();
										this.toastr.success('Iniciando gravação!');
										
										this.deviceObject.livereportId = data.reportId;
										this.deviceObject.isRecording = true;
									});
								}
							}
						}
					}
