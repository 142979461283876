
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

import { InternalComponent } from './internal/internal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevicesComponent } from './devices/devices.component';
import { ResumeCompanyComponent } from './admin/company/resume.component';
import { AccountsComponent } from './admin/accounts/accounts.component';
import { ShowcaseComponent } from './admin/showcase/showcase.component';
import { SuperAdminComponent } from './superadmin/superadmin.component';
import { ConnectComponent } from './connect/connect.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { AppsComponent } from './apps/apps.component';
import { ScriptsComponent } from './scripts/scripts.component';
import { ReportDetailComponent } from './report-tree/reports/report-detail/report-detail.component';
import { ReportsComponent } from './report-tree/reports/reports.component';
import { ReportBySessionComponent } from './report-tree/reportBySession/reportBySession.component';
import { ExecutionComponent } from './report-tree/execution/execution.component';
import { ReportTreeComponent } from './report-tree/report-tree.component';
import { LiveReportsComponent } from './report-tree/liveReport/liveReport.component';
import { WatcherComponent } from './watcher/watcher.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { TestSuitesComponent } from './test-suites/test-suites.component';
import { DeviceAccessLogComponent } from './deviceAccessLog/device-access-log.component';
import { ActionHistoryComponent } from './admin/action-history/action-history.component';
import { CustomLoginComponent } from './login/customLogin/customLogin.component';

const appRoutes: Routes = [
	{
		path: '',
		component: InternalComponent,
		children: [
			{
				path: '',
				component: DashboardComponent
			},
			{
				path: 'dashboard',
				component: DashboardComponent
			},
			{
				path: 'apps',
				component: AppsComponent
			},
			{
				path: 'devices',
				component: DevicesComponent
			},
			{
				path: 'connect',
				component: ConnectComponent
			},
			{
				path: 'testsuite',
				component: ConnectComponent
			},
			{
				path: 'scripts',
				component: ScriptsComponent
			},
			{
				path: 'test-suites',
				component: TestSuitesComponent
			},
			/*{
				path: 'devices/connect',
				component: ConnectComponent
			},
			{
				path: 'testCase/editor',
				component: ConnectComponent
			},*/
			// {
			// 	path: 'admin/pending-devices',
			// 	component: PendingDevicesComponent
			// },
			{
				path: 'admin/showcases',
				component: ShowcaseComponent
			},
			{
				path: 'admin/action/history',
				component: ActionHistoryComponent
			},
			{
				path: 'admin',
				component: AccountsComponent
			},
			{
				path: 'superadmin',
				component: SuperAdminComponent
			},
			{
				path: 'report-suites/:executionId',
				component: ExecutionComponent,
			},
			{
				path: 'report-suites/:executionId/:deviceId',
				component: ReportsComponent,
			},
			{
				path: 'report-suites/:executionId/:deviceId/:reportId',
				component: ReportDetailComponent,
			},
			{
				path: 'report/:sessionId',
				component: ReportBySessionComponent
			},
			{
				path: 'reports',
				component: ReportTreeComponent,
			},
			{
				path: 'reports/livereport/:id',
				component: LiveReportsComponent,
			},
			{
				path: 'device-access-log',
				component: DeviceAccessLogComponent
			},
			{
				path: 'configuration',
				component: ConfigurationComponent
			},
			{
				path: 'resumecompany',
				component: ResumeCompanyComponent
			}
		]
	},
	{path: 'change-password', component: ChangePasswordComponent},
	{path: 'login', component: LoginComponent },
	{path: 'login/:customPath', component: CustomLoginComponent},
	{path: 'watcher', component: WatcherComponent}
];

export const routing = RouterModule.forRoot(appRoutes, { useHash: true });
