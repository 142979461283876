import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap, NavigationEnd} from '@angular/router';
import { TestsService, TestCase, TestSuite, TestVariable, TestCapability } from '../../../tests/tests.service';
import { TestComponent } from '../test.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-testcase-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideComponent implements OnInit, OnDestroy, OnChanges {

	@ViewChild('capabilitiesBox') capabilitiesBox: ElementRef;
	@ViewChild('variablesBox') variablesBox: ElementRef;
	@ViewChild('casesBox') casesBox: ElementRef;

	navigationSubscription: any;
	testCaseIndex: any;

  constructor(private  testsService: TestsService,
		private cd: ChangeDetectorRef,
		public dialog: MatDialog,
		private zone: NgZone,
		private router: Router) {
		this.navigationSubscription = this.router.events.subscribe((e: any) => {
    	cd.detectChanges();
		});
	}

	@Input() testComp: TestComponent;

	ngOnInit() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['testComp']) {
		  this.cd.detectChanges();
		}
	  }

	ngOnDestroy() {
		if (this.navigationSubscription) {
       this.navigationSubscription.unsubscribe();
    }
	}

	renameTestSuite() {
		const name = prompt('Digite o nome da suíte de teste:', this.testComp.testSuite.name.toString());

		if (!name) {
			return;
		}

		this.testsService.renameTestSuite(this.testComp.testSuite, name).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

	addVar() {
		const name = prompt('Digite o nome da variável');

		if (!name) {
			return;
		}
		this.testComp.testSuite.variables.push(new TestVariable(name));

		this.testsService.editTestSuiteVar(this.testComp.testSuite).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			 },
			 (error: any) => {
					console.log(error);
			 },
		 );
	}

	editVar(index: number) {

		let oldVal;

		if (this.testComp.testSuite.variables[index].value) {
			oldVal = this.testComp.testSuite.variables[index].value.toString();
		} else {
			oldVal = '';
		}

		const value = prompt('Digite o valor padrão da variavel:', oldVal);

		if (!value) {
			return;
		}

		this.testComp.testSuite.variables[index].value = value;

		this.testsService.editTestSuiteVar(this.testComp.testSuite).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

	removeVar(index: number) {
		this.testComp.testSuite.variables.splice(index, 1);

		this.testsService.editTestSuiteVar(this.testComp.testSuite).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

	renameTestCase(index: number) {
		const name = prompt('Digite o nome do caso de teste:', this.testComp.testSuite.testCases[index].name.toString());

		if (!name) {
			return;
		}

		this.testsService.renameTestCase(this.testComp.testSuite, index, name).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

	removeTestCase(index: number) {

		this.testsService.removeTestCase(this.testComp.testSuite, index).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.testComp.activeTestCase = null;
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );
	}

	turnGeneric(index: number) {
		let tcase:any = this.testComp.testSuite.testCases[index];

		if(tcase.type !== 'generic'){
			this.testsService.turnGenericTestCase(this.testComp.testSuite, index).subscribe(
				 (result: any) => {
					 console.log(result.json())
					 this.zone.run(() => {
						 this.testComp.testSuite = result.json();
						 this.testComp.activeTestCase = null;
					 });
				 },
				 (error: any) => {
						 console.log(error);
				 },
			 );
		}else{
			alert('Esse teste case já é genérico.');
			console.log('Esse teste case já é genérico.');
		}
	}

	importTestCase() {
		const dialogRef = this.dialog.open(ImportDialogComponent, {
			width: '700px',
			height: '500px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result == null) {
				return;
			}

			console.log(result);

			this.testsService.importTestCase(result._id).subscribe(
				 (importResult: any) => {
					this.testComp.testSuite = importResult.json();
					this.testComp.activeTestCase = this.testComp.testSuite.testCases[this.testComp.testSuite.testCases.length - 1];
					this.cd.detectChanges();
				 },
				 (error: any) => {
						console.log(error);
				 },
			 );
		});
	}

	addTestCase() {
		const name = prompt('Digite o nome do caso de teste:');

		if (!name) {
			return;
		}

		this.testsService.addTestCase(name).subscribe(
			 (result: any) => {
				this.testComp.testSuite = result.json();
				this.testComp.activeTestCase = this.testComp.testSuite.testCases[this.testComp.testSuite.testCases.length - 1];
				this.cd.detectChanges();
			 },
			 (error: any) => {
				 	console.log(error);
			 },
		 );

	}

	addCapability(name: String) {
		const value = prompt(`Digite o ${name} do app`);
		if (!value) {
			return;
		}

		this.saveCapability(name, value);
	}

	onDrag(event: any) {
		this.testsService.reorderTestCases();
		this.cd.detectChanges();
	}

	drop(event: any){
		moveItemInArray(this.testComp.testSuite.testCases, event.previousIndex, event.currentIndex);
		this.testsService.reorderTestCases();
		this.cd.detectChanges();
	}

	addCustomCapability() {
		const name = prompt(`Digite a chave da capability`);
		if (!name) {
			return;
		}

		const value = prompt(`Digite o valor de ${name}`);
		if (!name) {
			return;
		}

		this.saveCapability(name, value);
	}

	saveCapability(name, value) {
		this.testComp.testSuite.capabilities.push(new TestCapability(name, value));

		this.testsService.editTestSuiteCapability(this.testComp.testSuite).subscribe(
			(result: any) => {
				this.testComp.testSuite = result.json();
				this.cd.detectChanges();
			},
			(error: any) => {
				console.log(error);
			},
		);
	}

	editCapability(index: number) {
		const cap = this.testComp.testSuite.capabilities[index];

		let value = prompt(`Digite o valor de ${cap.name}`, cap.value.toString());
		if (!value ) {
			return;
		}

		cap.value = value;

		this.testsService.editTestSuiteCapability(this.testComp.testSuite).subscribe(
			(result: any) => {
			   this.testComp.testSuite = result.json();
				 this.cd.detectChanges();
			},
			(error: any) => {
					console.log(error);
			},
		);
	}

	removeCapability(index: number) {
		this.testsService.removeCapability(this.testComp.testSuite, index).subscribe(
			(result: any) => {
			   this.testComp.testSuite = result.json();
				 this.cd.detectChanges();
			},
			(error: any) => {
				console.log(error);
			},
		);
	}

	trackByFn(index: number, item: any): any {
		return item.id || index; // Use uma propriedade única como ID, ou o índice como fallback
	}

	update() {
		this.cd.detectChanges();
	}

	toogleBox(elmt: ElementRef) {
		const el = elmt.nativeElement as HTMLElement;

		if (el.classList.contains('hide-collapse')) {
			el.classList.remove('hide-collapse');
			el.classList.add('show-collapse');
		} else {
			el.classList.remove('show-collapse');
			el.classList.add('hide-collapse');
		}
	}
}
