//ios
import { Component, OnInit } from '@angular/core';
import { InspectorComponent } from '../inspector.component';

@Component({
	selector: 'app-tooltab-inspector-ios',
	templateUrl: './ios-inspector.component.html',
	styleUrls: [
		'../inspector.component.scss',
		'./ios-inspector.component.scss'
	]
})
export class IOSInspectorComponent extends InspectorComponent implements OnInit {

	nodes: any;

	parseInspector(data: any, index: Number, name?: string) {

		const node: any = {};

		Object.keys(data).forEach((k) => {
			if (typeof data[k] === 'object' && k !== 'props') {
				data[k].forEach((el: any, i: Number) => {
					if (!node.children) {
						node.children = [];
					}

					node.children.push(this.parseInspector(el, i));

				});
			}
		});

		if (data.props) {
			node.name = data.props.type;
			node.props = data.props;
			node.props.index = index;
		} else {
			node.name = name;
		}

		return node;
	}

	load() {
		const actionRequestId = this.appService.uuid();
		this.working = true;
		this.connectService.sendAction(
			this.deviceObject.device._id,
			actionRequestId, 'source', {}).then((result: any) => {
				this.zone.run(() => {
					this.working = false;
					console.log(result.data);
					const key = Object.keys(result.data).find((k) => k !== 'props');
					const root = result.data[key];
					this.nodes = root.map((el: any) => this.parseInspector(el, 0, key));
					console.log(this.nodes);
				});
			});
	}

	onActivate(event: any) {
		if (event.node.data && event.node.data.props) {
			this.selectedElement = event.node.data;
			this.selectedElement.props.xpath = this.buildXpath(event.node, '');

			const canvas = this.deviceObject.stage.canvas.nativeElement;
			
			const bounds = this.selectedElement.props;

			const g = canvas.getContext('2d');
			let blob = new Blob([this.deviceObject.screen], { type: 'image/jpeg' });
			const URL = window.URL || window['webkitURL'];
			let img = new Image();
			let u = URL.createObjectURL(blob);

			const deviceObject = this.deviceObject;
			const ref = this;
			let hRatio = (window.innerHeight - 100) / deviceObject.device.model.logicalResolution.height;

			img.onload = function () {

				if (img.width == img.height && ref.deviceObject.iOSorientation == 'portrait') {
					let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
					canvas.setAttribute('width', Math.round(img.height / rt));
					canvas.setAttribute('height', img.height);
					
					//agr agt calcula pra n printar a barra preta
					let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
					g.drawImage(img, calcW, 0);
				} else if (img.width == img.height && ref.deviceObject.iOSorientation == 'landscape') {
					let rt = ref.deviceObject.device.model.logicalResolution.height / ref.deviceObject.device.model.logicalResolution.width;
					canvas.setAttribute('width', img.height);
					canvas.setAttribute('height', Math.round(img.height / rt));
					
					let calcW = ((img.height - Math.round(img.height / rt)) / 2) * -1;
					g.drawImage(img, 0, calcW);
				} else {
					canvas.setAttribute('width', img.width);
					canvas.setAttribute('height', img.height);
					
					g.drawImage(img, 0, 0);
				}

				g.beginPath();
				g.rect(bounds.x, bounds.y, bounds.width, bounds.height);
				g.lineWidth = 4;
				g.strokeStyle = 'red';
				g.stroke();

				img.onload = null;
				img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
				img = null;
				u = null;
				blob = null;
			};
			img.src = u;
		}
	}

	buildXpath(node: any, path: any): string {

		if (!node.data.props) {
			return path;
		}

		if (node.data.props.index === 0) {
			path = node.data.props.type + path;
		} else {
			path = node.data.props.type + '[' + (node.data.props.index + 1) + ']' + path;
		}

		if (node.parent) {
			return this.buildXpath(node.parent, '/' + path);
		} else {
			return path;
		}
	}

	actionValidate(subType: string) {
		this.testsService.testComponent.addValidation({
			reference: this.selectedElement.props[subType],
			method: subType,
		}, this.selectedElement, this.deviceObject)
	}

	actionTouch(subType: string) {

		const propagation = [this.deviceObject];

		if (this.deviceObject.replicate) {
			this.connectService.activeDevices.forEach((deviceConnection) => {
				if (deviceConnection.device._id !== this.deviceObject.device._id && deviceConnection.replicate) {
					propagation.push(deviceConnection);
				}
			});
		}

		this.testsService.testComponent.addTouchAction({
			reference: this.selectedElement.props[subType],
			method: subType,
		}, 'element', this.deviceObject);

		this.working = true;
		propagation.forEach((deviceConnection) => {
			const actionRequestId = this.appService.uuid();
			this.connectService.sendAction(
				deviceConnection.device._id,
				actionRequestId, 'live', {
				action: 'touch',
				method: subType,
				reference: this.selectedElement.props[subType]
			}).then((result: any) => {
				this.working = false;
				//this.zone.run(() => {
				console.log(result.data);
				if (deviceConnection.device._id == this.deviceObject.device._id) {
					setTimeout(() => { this.refresh() }, 1000);
				}
				//});
			});
		});
	}

	actionType(method) {

		this.testsService.testComponent.addKeyboard(method, this.selectedElement.props[method]).then((text: String) => {
			if (!text || text.length === 0) {
				return;
			}

			if (!this.connectService.activeDevice) {
				return;
			}

			const propagation = [this.connectService.activeDevice];

			if (propagation[0].replicate) {
				this.connectService.activeDevices.forEach((deviceConnection) => {
					if (deviceConnection.device._id !== propagation[0].device._id && deviceConnection.replicate) {
						propagation.push(deviceConnection);
					}
				});
			}

			propagation.forEach((deviceConnection) => {
				const actionRequestId = this.appService.uuid();
				this.connectService.sendAction(
					deviceConnection.device._id,
					actionRequestId, 'live', {
					action: 'type',
					method: method,
					reference: this.selectedElement.props[method],
					text: text,
				}).then((result: any) => {
					console.log(result.data);
					if (deviceConnection.device._id === this.deviceObject.device._id) {
						setTimeout(() => { this.refresh() }, 1000);
					}
				});
			});
		});
	}

	findBestMatch(tree, position) {
		let smallestElement = null;
		let smallestSize = Infinity;

		function traverse(node) {
			if (!node) return;

			// Converte os valores de bounds para números
			const bounds = {
				x: Number(node.props.x),
				y: Number(node.props.y),
				width: Number(node.props.width),
				height: Number(node.props.height)
			};

			const { x, y, width, height } = bounds;
			const { x: px, y: py } = position;

			// Verifica se a posição está dentro da área do elemento
			if (px >= x && px <= x + width && py >= y && py <= y + height) {
				const size = width * height;

				// Verifica se o tamanho é menor que o menor encontrado até agora
				if (size < smallestSize) {
					smallestSize = size;
					smallestElement = node;
				}
			}

			// Percorre os filhos (children) se houver
			if (node.children && Array.isArray(node.children)) {
				for (const child of node.children) {
					traverse(child);
				}
			}
		}

		traverse(tree);

		return smallestElement;
	}

	selectElementByPosition(pos: any) {
		const element = this.findBestMatch(this.nodes[0], pos);

		if (element) {
			const node = this.tree.treeModel.getNodeById(element.id);
			this.tree.treeModel.fireEvent({ eventName: 'activate', node });
			node.focus();
			node.ensureVisible();
			this.tree.treeModel.setFocusedNode(node);
		}
	}

	deepPositionSearch(tree: any, pos: any, bestMatch?: any): any {

		tree.forEach((el: any) => {
			if (el.props.x !== undefined) {

				el.props.width = Number(el.props.width);
				el.props.height = Number(el.props.height);
				el.props.x = Number(el.props.x);
				el.props.y = Number(el.props.y);

				const x0 = el.props.x;
				const y0 = el.props.y;
				const x1 = x0 + el.props.width;
				const y1 = y0 + el.props.height;

				if ((pos.x >= x0 && pos.x <= x1) && (pos.y >= y0 && pos.y <= y1)) {
					if (!bestMatch) {
						bestMatch = el;
					} else {

						bestMatch.props.x = Number(bestMatch.props.x);
						bestMatch.props.y = Number(bestMatch.props.y);
						bestMatch.props.width = Number(bestMatch.props.width);
						bestMatch.props.height = Number(bestMatch.props.height);

						const elDx = x1 - x0;
						const elDy = y1 - y0;
						const bmDx = (bestMatch.props.x + bestMatch.props.width) - bestMatch.props.x;
						const bmDy = (bestMatch.props.y + bestMatch.props.height) - bestMatch.props.y;

						if ((el.props.width + el.props.height) < (bestMatch.props.width + bestMatch.props.height)) {
							console.log((elDx + elDy), (bmDx + bmDy));
							bestMatch = el;
						}
					}
				}
			}

			if (el.children) {
				bestMatch = this.deepPositionSearch(el.children, pos, bestMatch);
			}

		});

		return bestMatch;
	}
}
