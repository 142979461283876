import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone, ViewChild } from '@angular/core';
import { TestsService, TestCase, TestSuite, TestStep, TestFolder, TestCapability } from '../../tests/tests.service';
import { Router, ActivatedRoute, Params, NavigationExtras, ParamMap, NavigationEnd } from '@angular/router';
import { DelayComponent } from './dialogs/delay/delay.component';
import { ScriptComponent } from './dialogs/script/script.component';
import { KeyboardComponent } from './dialogs/keyboard/keyboard.component';
import { TouchComponent } from './dialogs/touch/touch.component';
import { FolderComponent } from './dialogs/folder/folder.component';
import { ValidateComponent } from './dialogs/validate/validate.component';
import { ConnectService } from '../connect.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../app.service';
import { SideComponent } from './side/side.component';

@Component({
	selector: 'app-connect-test',
	templateUrl: './test.component.html',
	styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {

	testSuite: TestSuite;
	activeTestCase: TestCase;
	activeFolder: any[] = [];
	activeStepIndex: number;
	isReccording: Boolean;
	isEditing: Boolean;
	navigationSubscription: any;

	@ViewChild('side') sideComponent: SideComponent;

	constructor(
		public dialog: MatDialog,
		private connectService: ConnectService,
		private testsService: TestsService,
		private changeDetector: ChangeDetectorRef,
		private router: Router,
		private appService: AppService,
		private route: ActivatedRoute,
		private zone: NgZone
	) {

		this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.loadTestSuite();
			}
		});
	}

	ngOnDestroy() {
		if (this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	loadTestSuite() {
		const testSuiteId = this.route.snapshot.queryParams.id;
		if (testSuiteId) {
			this.testsService.viewTestSuite(testSuiteId).subscribe(
				(result: any) => {
					this.testsService.testComponent.testSuite = result.json();
					this.changeDetector.detectChanges();
					this.sideComponent.update();
				},
				(error: any) => {
					console.log(error);
				});
		}
	}

	ngOnInit() {
		this.testsService.testComponent = this;
	}

	toggleRec() {
		this.isReccording = !this.isReccording;
	}

	getSetupPackage() {
		let ref = this;
		if(!this.connectService.activeDevice){
			this.connectService.activeDevice = this.connectService.activeDevices[0];
		}

		const actionRequestId = this.appService.uuid();
		this.connectService.sendAction(
			this.connectService.activeDevice.device._id,
			actionRequestId, 'live', {
			action: 'getPackage'
		}).then((result: any) => {
			ref.testSuite.capabilities = [];
			if(result.data['bundleId']){
				ref.testSuite.capabilities.push(new TestCapability(`bundleId`, result.data.bundleId));
			} else {
				ref.testSuite.capabilities.push(new TestCapability(`appPackage`, result.data.appPackage));
				ref.testSuite.capabilities.push(new TestCapability(`appActivity`, result.data.appActivity));
			}

			ref.testsService.editTestSuiteCapability(this.testSuite).subscribe(
				(result: any) => {
					ref.zone.run(() => {
						ref.testSuite = result.json();
						ref.testSuite.capabilities = [...ref.testSuite.capabilities];
						ref.changeDetector.detectChanges();
						const h4Element = Array.from(document.querySelectorAll('h4')).find(el => el.textContent.includes('Capability:'));
						h4Element.click();
						h4Element.click();
					})
				},
				(error: any) => {
					console.log(error);
				},
			);
		});
	}

	newTestSuite() {
		const name = prompt('Digite o nome da suíte de teste:');

		if (!name) {
			return;
		}

		this.testSuite = new TestSuite();
		this.testSuite.name = name;

		this.testsService.createTestSuite(name).subscribe(
			(result: any) => {
				this.testSuite = result.json();

				this.testsService.getSuiteTree().subscribe((treeResult) => {
					const suiteTree = treeResult.json().root;
					suiteTree.push({
						type: 'test-suite',
						value: this.testSuite._id,
					});

					this.testsService.updateSuiteTree(suiteTree).subscribe((response) => {
						this.router.navigate(['/testsuite'], { queryParams: { 'id': this.testSuite._id } });
					}, (error) => {
						console.error(error);
					});
				});
			},
			(error: any) => {
				console.log(error);
			},
		);
	}

	addTouchAction(clickObj: any, subType: string, deviceObj: any) {

		const step: TestStep = new TestStep();
		step.type = 'touchAction';
		step.subType = subType;

		if (subType === 'relative') {
			step.setTouchAction(clickObj, deviceObj);
		} else {
			step.defaultValue = clickObj;
		}

		if (this.isEditing && this.activeStepIndex !== -1) {
			this.activeTestCase.steps[this.activeStepIndex] = step;
		} else if (this.isReccording) {
			const folder = this.getActiveFolder();
			if (folder != null) {
				folder.subSteps.push(step);
			} else {
				this.testsService.testComponent.activeTestCase.steps.push(step);
			}
		}

		this.isEditing = false;
		this.testsService.updateSteps();
	}

	addPressKeyAction(key: any, deviceObj: any) {

		const step: TestStep = new TestStep();
		step.type = 'pressKey';
		step.defaultValue = key;

		if (this.isEditing && this.activeStepIndex !== -1) {
			this.activeTestCase.steps[this.activeStepIndex] = step;
		} else if (this.isReccording) {
			const folder = this.getActiveFolder();
			if (folder != null) {
				folder.subSteps.push(step);
			} else {
				this.testsService.testComponent.activeTestCase.steps.push(step);
			}
		}

		this.isEditing = false;
		this.testsService.updateSteps();
	}

	addTouchElement() {
		let data = {};

		if (this.isEditing) {
			data = {
				step: this.activeTestCase.steps[this.activeStepIndex]
			};
		}

		const dialogRef = this.dialog.open(TouchComponent, {
			data: data,
			width: '420px',
			height: '380px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result == null) {
				this.isEditing = false;
				this.activeStepIndex = -1;
				return;
			}

			if (this.isEditing) {
				this.activeTestCase.steps[this.activeStepIndex].defaultValue = result;
			}

			this.isEditing = false;
			this.testsService.updateSteps();
		});
	}

	addScript() {
		let data = {};

		if (this.testSuite /*this.isEditing*/) {
			data = {
				step: this.activeTestCase.steps[this.activeStepIndex],
				testSuite: this.testSuite,
			};
		}

		const dialogRef = this.dialog.open(ScriptComponent, {
			data: data,
			width: '900px',
			height: '505px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result == null) {
				this.isEditing = false;
				this.activeStepIndex = -1;
				return;
			}

			if (this.isEditing) {
				this.activeTestCase.steps[this.activeStepIndex].defaultValue = result;
			} else {
				const step = new TestStep();
				step.type = 'script';
				step.defaultValue = result;

				console.log(step);

				const folder = this.getActiveFolder();
				if (folder != null) {
					folder.subSteps.push(step);
				} else {
					this.testsService.testComponent.activeTestCase.steps.push(step);
				}
			}

			this.isEditing = false;
			this.testsService.updateSteps();
		});
	}

	addValidation(validateObj?: any, element?: any, deviceObj?: any) {

		console.log(element);

		const data = {
			element: validateObj,
			step: undefined,
			props: element != null ? element.props : null,
		};

		if (this.isEditing) {
			data.step = this.activeTestCase.steps[this.activeStepIndex];
		}

		const dialogRef = this.dialog.open(ValidateComponent, {
			data: data,
			width: '500px',
			height: '720px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (!result) {
				this.isEditing = false;
				this.testsService.updateSteps();
				return;
			}

			const step: TestStep = new TestStep();
			step.type = 'validate';
			step.defaultValue = result;

			//
			if (this.isEditing && this.activeStepIndex !== -1) {
				this.activeTestCase.steps[this.activeStepIndex] = step;
			} else /*if (this.isReccording)*/ {
				const folder = this.getActiveFolder();
				if (folder != null) {
					folder.subSteps.push(step);
				} else {
					this.testsService.testComponent.activeTestCase.steps.push(step);
				}
			}

			this.isEditing = false;
			this.testsService.updateSteps();
			//
		});
	}

	addDelay() {

		let data = {};

		if (this.isEditing) {
			data = {
				step: this.activeTestCase.steps[this.activeStepIndex]
			};
		}

		const dialogRef = this.dialog.open(DelayComponent, {
			data: data,
			width: '300px',
			height: '300px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result == null) {
				this.isEditing = false;
				this.activeStepIndex = -1;
				return;
			}

			if (this.isEditing) {
				this.activeTestCase.steps[this.activeStepIndex].defaultValue = result;
			} else if (this.isReccording) {
				const step = new TestStep();
				step.type = 'delay';
				step.defaultValue = result;

				const folder = this.getActiveFolder();
				if (folder != null) {
					folder.subSteps.push(step);
				} else {
					this.testsService.testComponent.activeTestCase.steps.push(step);
				}
			}

			this.isEditing = false;
			this.testsService.updateSteps();
		});
	}

	sendKeys(method, reference) {
		this.addKeyboard(method, reference).then((text: String) => {

			if (!text || text.length === 0) {
				return;
			}

			if (!this.connectService.activeDevice) {
				return;
			}

			const propagation = [this.connectService.activeDevice];

			if (propagation[0].replicate) {
				this.connectService.activeDevices.forEach((deviceConnection) => {
					if (deviceConnection.device._id !== propagation[0].device._id && deviceConnection.replicate) {
						propagation.push(deviceConnection);
					}
				});
			}

			propagation.forEach((deviceConnection) => {
				const actionRequestId = this.appService.uuid();
				this.connectService.sendAction(
					deviceConnection.device._id,
					actionRequestId, 'live', {
					action: 'type',
					method: method,
					reference: reference,
					text: text,
				}).then((result: any) => {
					console.log(result.data);
				});
			});
		});
	}

	addKeyboard(method, reference) {
		return new Promise((resolve, reject) => {
			let data = {};

			let text: String = '';

			if (this.isEditing) {
				data = {
					step: this.activeTestCase.steps[this.activeStepIndex]
				};
			}

			this.connectService.activeEnhancedKeyboard = false;
			const dialogRef = this.dialog.open(KeyboardComponent, {
				data: data,
				width: '300px',
				height: '350px',
			});

			dialogRef.afterClosed().subscribe(result => {
				this.connectService.activeEnhancedKeyboard = true;

				if (result == null) {
					this.isEditing = false;
					this.activeStepIndex = -1;
					return reject();
				}

				if (result.type === 'static') {
					text = result.value;
				} else {
					const variable = this.testsService.testComponent.testSuite.variables.find((v) => v.name === result.value);
					text = variable.value;
				}

				if (this.isEditing) {
					this.activeTestCase.steps[this.activeStepIndex].defaultValue.value = result.value;
					this.activeTestCase.steps[this.activeStepIndex].subType = result.type;
				} else if (this.isReccording) {
					const step = new TestStep();
					step.type = 'keyboard';
					step.subType = result.type;
					step.defaultValue = {
						method: method,
						reference: reference,
						value: result.value
					};

					const folder = this.getActiveFolder();
					if (folder != null) {
						folder.subSteps.push(step);
					} else {
						this.testsService.testComponent.activeTestCase.steps.push(step);
					}
				}

				this.isEditing = false;
				this.testsService.updateSteps();

				resolve(text);
			});
		});
	}

	getActiveFolder() {

		let step;

		for (let i = 0; i < this.activeFolder.length; i++) {
			if (!step) {
				step = this.testsService.testComponent.activeTestCase.steps[this.activeFolder[i]];
				console.log(step);
			} else {
				console.log(step);
				step = step.subSteps[this.activeFolder[i]];
			}
		}

		return step;
	}

	addFolder() {

		let data = {};

		if (this.isEditing) {
			data = {
				step: this.activeTestCase.steps[this.activeStepIndex]
			};
		}

		const dialogRef = this.dialog.open(FolderComponent, {
			data: data,
			width: '300px',
			height: '300px',
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result == null) {
				this.isEditing = false;
				this.activeStepIndex = -1;
				return;
			}

			const step = new TestFolder();
			step.alias = result;

			const folder = this.getActiveFolder();
			if (folder != null) {
				folder.subSteps.push(step);
			} else {
				this.testsService.testComponent.activeTestCase.steps.push(step);
			}

			this.isEditing = false;
			this.testsService.updateSteps();
		});
	}
}
